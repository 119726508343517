import useScrollToTop from '@/app/hooks/useScrollToTop'
import useAppStore from '@/store/app-store'

export default function EarlyExitComplete() {
  useScrollToTop()
  useAppStore.setState({
    ineligible: true
  })
  var accountType = useAppStore(state => state.accountType)
  if (accountType === 'trust') {
    useAppStore.setState({
      progress: 14
    })
  } else if (accountType === 'joint') {
    useAppStore.setState({
      progress: 13
    })
  } else {
    useAppStore.setState({
      progress: 10
    })
  }
  return (
    <div className='flex justify-center'>
      <a
        href='/'
        target='_PARENT'
        className='bg-navy/10 px-6 py-3 text-sm uppercase text-navy'
      >
        Back to Home
      </a>
    </div>
  )
}
